import React from "react";
import { StaticImage, getSrc } from "gatsby-plugin-image";
import { PageContent, Heading, Paragraph, Box } from "grommet";
import Page from "../components/page";
import UL from "../components/ul";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Le gîte des écuries du cerf"
        description="Le gîte des écuries du cerf vous accuille tout au long de l'année."
      />
      <PageContent>
        <Heading alignSelf="center">Gîte</Heading>
        <Box height="">
          <StaticImage
            src="../images/gite.jpg"
            alt="Le gîte des écuries du cerf"
            layout="constrained"
          />
        </Box>
        <Paragraph fill>
          Le gîte du Cerf est un gîte au sein de la propriété équestre.
          <br />
          Il est au pied de plusieurs hectares de forêt où il est bon de
          randonner et de se ressourcer.
        </Paragraph>
        <Paragraph fill>
          Le gîte peut accueillir 4 personnes. Il y a une chambre avec un lit
          double et une chambre avec deux lits simples.
          <br />
          Le gîte fait 51m<sup>2</sup> et se compose de deux chambres séparées.
          L'accès à la salle de douche ne se fait que par la chambre lit double.
        </Paragraph>
        <Paragraph fill>
          Le gîte a une cuisine aménagée équipée ouverte sur la salle salon. Il
          y a une terrasse 15m<sup>2</sup> et un terrain clos de 60m
          <sup>2</sup> donnant sur les paddocks des chevaux. Un emplacement pour
          votre voiture est prévu à côté du gîte.
          <br />
          Les animaux sont acceptés (vos chevaux aussi!), à savoir qu'ils
          doivent accepter aussi les animaux de la propriété (chiens, chats,
          poules, chevaux).
        </Paragraph>
        <Paragraph fill>
          Nous fournissons les draps de lit, les torchons, les serviettes de
          bain.
          <br />
          Tout le nécessaire bébé est mis à votre disposition soit un lit
          parapluie, une table à langer, pot pour bébé, chaise haute.
          <br />
          Le ménage du gîte doit être fait avant votre départ.
        </Paragraph>
        <UL>
          Les équipements:
          <li>Cafetière à filtre</li>
          <li>Cafetière tassimo</li>
          <li>Grille pain</li>
          <li>Bouilloire</li>
          <li>Wifi</li>
          <li>Télévision</li>
          <li>Aspirateur</li>
          <li>Balai nettoyant</li>
          <li>Lave linge</li>
          <li>Lave vaisselle</li>
          <li>Sèche cheveux</li>
        </UL>
        <Paragraph fill>
          La nuit est à 70€ (heure d'arrivée et heure de départ selon votre
          convenance) chauffage et eau inclus.
          <br />
          Un petit déjeuner est mis à votre disposition gratuitement pour le
          matin (préciser l'heure du petit déj́euner).
        </Paragraph>
        Pour le gîte de votre cheval il faut compter 20€ la nuit qui contient:
        <ul>
          <li>Box paille ou copeaux</li>
          <li>Nourriture matin et soir</li>
          <li>Foin 2 fois par jour</li>
          <li>Accès au paddocks dans la journée</li>
        </ul>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
