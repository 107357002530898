import React from "react";
import styled from "styled-components";

const UL = ({ children, ...others }) => {
  return <ul {...others}>{children}</ul>;
};

const ULStyled = styled(UL)`
  & li:first-child {
    padding-top: 16px;
  }
`;

export default ULStyled;
